/*
 * 认证状态-数据的集合(必须sync)
 */
// import comFun from "../../assets/utils/commonFun";

export default {
  /**
   * 存储用户信息
   * @param {Object} state [vuex自身状态]
   * @param {Object} { account  [登陆账号] userID [用户ID] }
   */
  UPDATE_AUTH_USER(state, params) {
    let userInfo = new Object()
    ;(userInfo['account'] = params.account), (userInfo['displayName'] = `${params.account}`), (userInfo['userID'] = params.userID), (userInfo['avatar'] = 'avatar-s-27.png')
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    state.account = params.account
  },
  DELECT_AUTH_USER(state, params) {
    localStorage.removeItem('userInfo')
    state.account = ''
  },

  /**
   * 存储接口登陆凭证
   * @param {Object} state [vuex自身状态]
   * @param {Object} { authToken [接口登陆凭证] authExpire [authToken的到期时间]}
   */
  UPDATE_AUTH_TOKEN(state, params) {
    let authTokenInfo = new Object()
    ;(authTokenInfo['authExpire'] = params.authExpire), (authTokenInfo['authToken'] = params.authToken)
    localStorage.setItem('authTokenInfo', JSON.stringify(authTokenInfo))
  },
  DELECT_AUTH_TOKEN(state, params) {
    localStorage.removeItem('authTokenInfo')
  },

  /**
   * 存储菜单
   * @param {Object} state [vuex自身状态]
   * @param {Array}  menu [菜单]
   */
  UPDATE_AUTH_MEUN(state, menu = []) {
    // 按照system_code排序
    let sortMenu = menu.sort((a, b) => {
      return Number(a['system_code']) - Number(b['system_code'])
    })

    // 转换菜单
    let rMenu = iterableRMenu(sortMenu)

    // 筛选权限菜单
    // console.log(menu);
    let authPath = siftMenus(sortMenu)
    // console.log(authPath);

    state.rmenu = rMenu
    localStorage.setItem('menu', JSON.stringify(menu))
    localStorage.setItem('rmenu', JSON.stringify(rMenu))
    localStorage.setItem('authPath', JSON.stringify(authPath))
  },
  DELECT_AUTH_MEUN(state, params) {
    state.rmenu = []
    localStorage.removeItem('menu')
    localStorage.removeItem('rmenu')
    localStorage.removeItem('authPath')
  },

  /**
   * 存储空间
   * @param {Object} state [vuex自身状态]
   * @param {Array}  spaces [空间]
   */
  UPDATE_AUTH_SPACES(state, spaces = []) {
    state.spaces = spaces
    localStorage.setItem('spaces', JSON.stringify(spaces))
  },
  DELECT_AUTH_SPACES(state, params) {
    state.spaces = []
    localStorage.removeItem('spaces')
  },
  /**
   * 存储当前选中的空间
   * @param {Object} state [vuex自身状态]
   * @param {Array}  spaceObj [空间完整对象]
   */
  UPDATE_CURRENT_SPACE(state, spaceObj = {}) {
    state.space = spaceObj
    localStorage.setItem('space', JSON.stringify(spaceObj))
  },
  DELECT_CURRENT_SPACE(state, params) {
    state.space = {}
    localStorage.removeItem('space')
  }
}

// 修改成组件需要的菜单结构 (只支持三级)
function iterableRMenu(array) {
  var result = []
  var headerArr = []
  array.forEach(item => {
    // 判断是否采集平台
    if (item.extend && item.extend.platform !== 'collection') {
      return false
    }
    // 存在header(只判读一级)
    if (item.extend && !headerArr.includes(item.extend.headerName)) {
      result.push({ header: item.extend.headerName })
      headerArr.push(item.extend.headerName)
    }
    // 改变数据格式
    let obj = {}
    obj.name = item.function_name
    obj.url = item.path || null
    obj.icon = item.icon || null
    if (item.children && item.children.length > 0) {
      let arrChild = []
      for (let itemChild of item.children) {
        let objChild = {}
        objChild.name = itemChild.function_name
        objChild.url = itemChild.path || null
        arrChild.push(objChild)
      }
      obj.submenu = arrChild
      arrChild = []
      result.push(obj)
    } else {
      result.push(obj)
    }
  })
  return result
}

// 筛选权限菜单 (只支持三级)
function siftMenus(array) {
  var result = []
  array.forEach(item => {
    if (item.children && item.children.length > 0) {
      for (let itemChild of item.children) {
        result.push(itemChild.path || null)
      }
    } else {
      result.push(item.path || null)
    }
  })
  return result
}

// 递归结果 不理想
// function recurisonMenu(array) {
//   for (let item of array) {
//     // 改变数据格式
//     if (!item.icon && !item.path && !item.children) {
//       item.header = item.function_name;
//     } else {
//       item.name = item.function_name;
//       item.url = item.path ? item.path : null;
//     }
//     // 删除不需要的
//     if (!item.icon) delete item.icon;
//     delete item.id;
//     delete item.p_id;
//     delete item.function_name;
//     delete item.path;
//     // 判断下子集
//     if (item.children) {
//       recurisonMenu(item.children);
//       item.submenu = item.children;
//       delete item.children;
//     }
//   }

// function traversalHeader(array) {
//   let newArray = [];
//   console.log(array);
//   // array.forEach(item => {
//   //   // 遍历第一层 添加heder
//   //   if (!comFun.isNullOrEmpty(item.extend)) {
//   //     newArray.push({ header: item.extend.headerName });
//   //   }
//   //   newArray.push(item);
//   // });
//   return newArray;
// }
