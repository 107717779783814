/*
 * 通用状态
 */
import navbarSearchAndPinList from "@/apis/mockData/顶部搜索内容.json";
import themeConfig from "@/../themeConfig.js";

const state = {
  // 窗体宽高
  windowWidth: null,
  windowHeight: null,
  // 侧边栏状态
  isSidebarActive: false,
  // 侧边栏最小化
  sidebarItemsMin: false,
  // 整体遮罩层
  bodyOverlay: false,
  // 整体视图大小 【"xl", "lg", "md"】
  breakpoint: null,
  // 侧边栏状态模型 基本都是default
  sidebarWidth: "default",
  // 侧边栏缩小按钮
  reduceButton: themeConfig.sidebarCollapsed,
  // 整体主题
  theme: localStorage.getItem("theme") || themeConfig.theme,
  // 整体主题色
  themePrimaryColor:
    localStorage.getItem("themePrimaryColor") || themeConfig.themePrimaryColor,
  // 整体过长动画
  routerTransition:
    localStorage.getItem("routerTransition") || themeConfig.routerTransition,
  // 顶部条颜色
  navbarColor: localStorage.getItem("navbarColor") || themeConfig.navbarColor,
  // 顶部条搜素内容
  navbarSearchAndPinList: navbarSearchAndPinList,
  // 用户信息 （status 0: 未知, 1: 在线, 2: 离线）
  userInfo: {
    account: "NONE",
    displayName: "虚拟管理员",
    avatar: "avatar-s-26.png",
    effectTime: "",
    status: 2
  }
};

export default state;
