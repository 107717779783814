/*
 * 全局组件 注册
 */
import Vue from "vue";
// 卡片组件
import VxCard from "./components/vx-card/VxCard.vue";
// 面包屑组件
import VxBreadcrumb from "./layouts/components//vx-breadcrumb/VxBreadcrumb.vue";
// 图标组件
import FeatherIcon from "./components/FeatherIcon.vue";

Vue.component(VxCard.name, VxCard);
Vue.component(VxBreadcrumb.name, VxBreadcrumb);
Vue.component(FeatherIcon.name, FeatherIcon);
