// eslint-disable-next-line
/* eslint-disable */
export const citydatas = [
    { 'code': '000000', 'title': '其他地区' },
    { 'code': '11010000', 'title':    '北京市' },
    { 'code': '120100000000', 'title': '天津市' },
    { 'code': '130000000000', 'title': '河北省' },
    { 'code': '130100000000', 'title': '石家庄市' },
    { 'code': '130200000000', 'title': '唐山市' },
    { 'code': '130300000000', 'title': '秦皇岛市' },
    { 'code': '130400000000', 'title': '邯郸市' },
    { 'code': '130500000000', 'title': '邢台市' },
    { 'code': '130600000000', 'title': '保定市' },
    { 'code': '130700000000', 'title': '张家口市' },
    { 'code': '130800000000', 'title': '承德市' },
    { 'code': '130900000000', 'title': '沧州市' },
    { 'code': '131000000000', 'title': '廊坊市' },
    { 'code': '131100000000', 'title': '衡水市' },
    { 'code': '140000000000', 'title': '山西省' },
    { 'code': '140100000000', 'title': '太原市' },
    { 'code': '140200000000', 'title': '大同市' },
    { 'code': '140300000000', 'title': '阳泉市' },
    { 'code': '140400000000', 'title': '长治市' },
    { 'code': '140500000000', 'title': '晋城市' },
    { 'code': '140600000000', 'title': '朔州市' },
    { 'code': '140700000000', 'title': '晋中市' },
    { 'code': '140800000000', 'title': '运城市' },
    { 'code': '140900000000', 'title': '忻州市' },
    { 'code': '141000000000', 'title': '临汾市' },
    { 'code': '141100000000', 'title': '吕梁市' },
    { 'code': '150000000000', 'title': '内蒙古自治区' },
    { 'code': '150100000000', 'title': '呼和浩特市' },
    { 'code': '150200000000', 'title': '包头市' },
    { 'code': '150300000000', 'title': '乌海市' },
    { 'code': '150400000000', 'title': '赤峰市' },
    { 'code': '150500000000', 'title': '通辽市' },
    { 'code': '150600000000', 'title': '鄂尔多斯市' },
    { 'code': '150700000000', 'title': '呼伦贝尔市' },
    { 'code': '150800000000', 'title': '巴彦淖尔市' },
    { 'code': '150900000000', 'title': '乌兰察布市' },
    { 'code': '152200000000', 'title': '兴安盟' },
    { 'code': '152500000000', 'title': '锡林郭勒盟' },
    { 'code': '152900000000', 'title': '阿拉善盟' },
    { 'code': '210000000000', 'title': '辽宁省' },
    { 'code': '210100000000', 'title': '沈阳市' },
    { 'code': '210200000000', 'title': '大连市' },
    { 'code': '210300000000', 'title': '鞍山市' },
    { 'code': '210400000000', 'title': '抚顺市' },
    { 'code': '210500000000', 'title': '本溪市' },
    { 'code': '210600000000', 'title': '丹东市' },
    { 'code': '210700000000', 'title': '锦州市' },
    { 'code': '210800000000', 'title': '营口市' },
    { 'code': '210900000000', 'title': '阜新市' },
    { 'code': '211000000000', 'title': '辽阳市' },
    { 'code': '211100000000', 'title': '盘锦市' },
    { 'code': '211200000000', 'title': '铁岭市' },
    { 'code': '211300000000', 'title': '朝阳市' },
    { 'code': '211400000000', 'title': '葫芦岛市' },
    { 'code': '220000000000', 'title': '吉林省' },
    { 'code': '220100000000', 'title': '长春市' },
    { 'code': '220200000000', 'title': '吉林市' },
    { 'code': '220300000000', 'title': '四平市' },
    { 'code': '220400000000', 'title': '辽源市' },
    { 'code': '220500000000', 'title': '通化市' },
    { 'code': '220600000000', 'title': '白山市' },
    { 'code': '220700000000', 'title': '松原市' },
    { 'code': '220800000000', 'title': '白城市' },
    { 'code': '222400000000', 'title': '延边朝鲜族自治州' },
    { 'code': '230000000000', 'title': '黑龙江省' },
    { 'code': '230100000000', 'title': '哈尔滨市' },
    { 'code': '230200000000', 'title': '齐齐哈尔市' },
    { 'code': '230300000000', 'title': '鸡西市' },
    { 'code': '230400000000', 'title': '鹤岗市' },
    { 'code': '230500000000', 'title': '双鸭山市' },
    { 'code': '230600000000', 'title': '大庆市' },
    { 'code': '230700000000', 'title': '伊春市' },
    { 'code': '230800000000', 'title': '佳木斯市' },
    { 'code': '230900000000', 'title': '七台河市' },
    { 'code': '231000000000', 'title': '牡丹江市' },
    { 'code': '231100000000', 'title': '黑河市' },
    { 'code': '231200000000', 'title': '绥化市' },
    { 'code': '232700000000', 'title': '大兴安岭地区' },
    { 'code': '310100000000', 'title': '上海市' },
    { 'code': '320000000000', 'title': '江苏省' },
    { 'code': '320100000000', 'title': '南京市' },
    { 'code': '320200000000', 'title': '无锡市' },
    { 'code': '320300000000', 'title': '徐州市' },
    { 'code': '320400000000', 'title': '常州市' },
    { 'code': '320500000000', 'title': '苏州市' },
    { 'code': '320600000000', 'title': '南通市' },
    { 'code': '320700000000', 'title': '连云港市' },
    { 'code': '320800000000', 'title': '淮安市' },
    { 'code': '320900000000', 'title': '盐城市' },
    { 'code': '321000000000', 'title': '扬州市' },
    { 'code': '321100000000', 'title': '镇江市' },
    { 'code': '321200000000', 'title': '泰州市' },
    { 'code': '321300000000', 'title': '宿迁市' },
    { 'code': '330000000000', 'title': '浙江省' },
    { 'code': '330100000000', 'title': '杭州市' },
    { 'code': '330200000000', 'title': '宁波市' },
    { 'code': '330300000000', 'title': '温州市' },
    { 'code': '330400000000', 'title': '嘉兴市' },
    { 'code': '330500000000', 'title': '湖州市' },
    { 'code': '330600000000', 'title': '绍兴市' },
    { 'code': '330700000000', 'title': '金华市' },
    { 'code': '330800000000', 'title': '衢州市' },
    { 'code': '330900000000', 'title': '舟山市' },
    { 'code': '331000000000', 'title': '台州市' },
    { 'code': '331100000000', 'title': '丽水市' },
    { 'code': '340000000000', 'title': '安徽省' },
    { 'code': '340100000000', 'title': '合肥市' },
    { 'code': '340200000000', 'title': '芜湖市' },
    { 'code': '340300000000', 'title': '蚌埠市' },
    { 'code': '340400000000', 'title': '淮南市' },
    { 'code': '340500000000', 'title': '马鞍山市' },
    { 'code': '340600000000', 'title': '淮北市' },
    { 'code': '340700000000', 'title': '铜陵市' },
    { 'code': '340800000000', 'title': '安庆市' },
    { 'code': '341000000000', 'title': '黄山市' },
    { 'code': '341100000000', 'title': '滁州市' },
    { 'code': '341200000000', 'title': '阜阳市' },
    { 'code': '341300000000', 'title': '宿州市' },
    { 'code': '341500000000', 'title': '六安市' },
    { 'code': '341600000000', 'title': '亳州市' },
    { 'code': '341700000000', 'title': '池州市' },
    { 'code': '341800000000', 'title': '宣城市' },
    { 'code': '350000000000', 'title': '福建省' },
    { 'code': '350100000000', 'title': '福州市' },
    { 'code': '350200000000', 'title': '厦门市' },
    { 'code': '350300000000', 'title': '莆田市' },
    { 'code': '350400000000', 'title': '三明市' },
    { 'code': '350500000000', 'title': '泉州市' },
    { 'code': '350600000000', 'title': '漳州市' },
    { 'code': '350700000000', 'title': '南平市' },
    { 'code': '350800000000', 'title': '龙岩市' },
    { 'code': '350900000000', 'title': '宁德市' },
    { 'code': '360000000000', 'title': '江西省' },
    { 'code': '360100000000', 'title': '南昌市' },
    { 'code': '360200000000', 'title': '景德镇市' },
    { 'code': '360300000000', 'title': '萍乡市' },
    { 'code': '360400000000', 'title': '九江市' },
    { 'code': '360500000000', 'title': '新余市' },
    { 'code': '360600000000', 'title': '鹰潭市' },
    { 'code': '360700000000', 'title': '赣州市' },
    { 'code': '360800000000', 'title': '吉安市' },
    { 'code': '360900000000', 'title': '宜春市' },
    { 'code': '361000000000', 'title': '抚州市' },
    { 'code': '361100000000', 'title': '上饶市' },
    { 'code': '370000000000', 'title': '山东省' },
    { 'code': '370100000000', 'title': '济南市' },
    { 'code': '370200000000', 'title': '青岛市' },
    { 'code': '370300000000', 'title': '淄博市' },
    { 'code': '370400000000', 'title': '枣庄市' },
    { 'code': '370500000000', 'title': '东营市' },
    { 'code': '370600000000', 'title': '烟台市' },
    { 'code': '370700000000', 'title': '潍坊市' },
    { 'code': '370800000000', 'title': '济宁市' },
    { 'code': '370900000000', 'title': '泰安市' },
    { 'code': '371000000000', 'title': '威海市' },
    { 'code': '371100000000', 'title': '日照市' },
    { 'code': '371300000000', 'title': '临沂市' },
    { 'code': '371400000000', 'title': '德州市' },
    { 'code': '371500000000', 'title': '聊城市' },
    { 'code': '371600000000', 'title': '滨州市' },
    { 'code': '371700000000', 'title': '菏泽市' },
    { 'code': '410000000000', 'title': '河南省' },
    { 'code': '410100000000', 'title': '郑州市' },
    { 'code': '410200000000', 'title': '开封市' },
    { 'code': '410300000000', 'title': '洛阳市' },
    { 'code': '410400000000', 'title': '平顶山市' },
    { 'code': '410500000000', 'title': '安阳市' },
    { 'code': '410600000000', 'title': '鹤壁市' },
    { 'code': '410700000000', 'title': '新乡市' },
    { 'code': '410800000000', 'title': '焦作市' },
    { 'code': '410900000000', 'title': '濮阳市' },
    { 'code': '411000000000', 'title': '许昌市' },
    { 'code': '411100000000', 'title': '漯河市' },
    { 'code': '411200000000', 'title': '三门峡市' },
    { 'code': '411300000000', 'title': '南阳市' },
    { 'code': '411400000000', 'title': '商丘市' },
    { 'code': '411500000000', 'title': '信阳市' },
    { 'code': '411600000000', 'title': '周口市' },
    { 'code': '411700000000', 'title': '驻马店市' },
    { 'code': '419000000000', 'title': '省直辖县级行政区划' },
    { 'code': '420000000000', 'title': '湖北省' },
    { 'code': '420100000000', 'title': '武汉市' },
    { 'code': '420200000000', 'title': '黄石市' },
    { 'code': '420300000000', 'title': '十堰市' },
    { 'code': '420500000000', 'title': '宜昌市' },
    { 'code': '420600000000', 'title': '襄阳市' },
    { 'code': '420700000000', 'title': '鄂州市' },
    { 'code': '420800000000', 'title': '荆门市' },
    { 'code': '420900000000', 'title': '孝感市' },
    { 'code': '421000000000', 'title': '荆州市' },
    { 'code': '421100000000', 'title': '黄冈市' },
    { 'code': '421200000000', 'title': '咸宁市' },
    { 'code': '421300000000', 'title': '随州市' },
    { 'code': '422800000000', 'title': '恩施土家族苗族自治州' },
    { 'code': '429000000000', 'title': '省直辖县级行政区划' },
    { 'code': '430000000000', 'title': '湖南省' },
    { 'code': '430100000000', 'title': '长沙市' },
    { 'code': '430200000000', 'title': '株洲市' },
    { 'code': '430300000000', 'title': '湘潭市' },
    { 'code': '430400000000', 'title': '衡阳市' },
    { 'code': '430500000000', 'title': '邵阳市' },
    { 'code': '430600000000', 'title': '岳阳市' },
    { 'code': '430700000000', 'title': '常德市' },
    { 'code': '430800000000', 'title': '张家界市' },
    { 'code': '430900000000', 'title': '益阳市' },
    { 'code': '431000000000', 'title': '郴州市' },
    { 'code': '431100000000', 'title': '永州市' },
    { 'code': '431200000000', 'title': '怀化市' },
    { 'code': '431300000000', 'title': '娄底市' },
    { 'code': '433100000000', 'title': '湘西土家族苗族自治州' },
    { 'code': '440000000000', 'title': '广东省' },
    { 'code': '440100000000', 'title': '广州市' },
    { 'code': '440200000000', 'title': '韶关市' },
    { 'code': '440300000000', 'title': '深圳市' },
    { 'code': '440400000000', 'title': '珠海市' },
    { 'code': '440500000000', 'title': '汕头市' },
    { 'code': '440600000000', 'title': '佛山市' },
    { 'code': '440700000000', 'title': '江门市' },
    { 'code': '440800000000', 'title': '湛江市' },
    { 'code': '440900000000', 'title': '茂名市' },
    { 'code': '441200000000', 'title': '肇庆市' },
    { 'code': '441300000000', 'title': '惠州市' },
    { 'code': '441400000000', 'title': '梅州市' },
    { 'code': '441500000000', 'title': '汕尾市' },
    { 'code': '441600000000', 'title': '河源市' },
    { 'code': '441700000000', 'title': '阳江市' },
    { 'code': '441800000000', 'title': '清远市' },
    { 'code': '441900000000', 'title': '东莞市' },
    { 'code': '442000000000', 'title': '中山市' },
    { 'code': '445100000000', 'title': '潮州市' },
    { 'code': '445200000000', 'title': '揭阳市' },
    { 'code': '445300000000', 'title': '云浮市' },
    { 'code': '450000000000', 'title': '广西壮族自治区' },
    { 'code': '450100000000', 'title': '南宁市' },
    { 'code': '450200000000', 'title': '柳州市' },
    { 'code': '450300000000', 'title': '桂林市' },
    { 'code': '450400000000', 'title': '梧州市' },
    { 'code': '450500000000', 'title': '北海市' },
    { 'code': '450600000000', 'title': '防城港市' },
    { 'code': '450700000000', 'title': '钦州市' },
    { 'code': '450800000000', 'title': '贵港市' },
    { 'code': '450900000000', 'title': '玉林市' },
    { 'code': '451000000000', 'title': '百色市' },
    { 'code': '451100000000', 'title': '贺州市' },
    { 'code': '451200000000', 'title': '河池市' },
    { 'code': '451300000000', 'title': '来宾市' },
    { 'code': '451400000000', 'title': '崇左市' },
    { 'code': '460000000000', 'title': '海南省' },
    { 'code': '460100000000', 'title': '海口市' },
    { 'code': '460200000000', 'title': '三亚市' },
    { 'code': '460300000000', 'title': '三沙市' },
    { 'code': '460400000000', 'title': '儋州市' },
    { 'code': '469000000000', 'title': '省直辖县级行政区划' },
    { 'code': '500100000000', 'title': '重庆市' },
    { 'code': '510000000000', 'title': '四川省' },
    { 'code': '510100000000', 'title': '成都市' },
    { 'code': '510300000000', 'title': '自贡市' },
    { 'code': '510400000000', 'title': '攀枝花市' },
    { 'code': '510500000000', 'title': '泸州市' },
    { 'code': '510600000000', 'title': '德阳市' },
    { 'code': '510700000000', 'title': '绵阳市' },
    { 'code': '510800000000', 'title': '广元市' },
    { 'code': '510900000000', 'title': '遂宁市' },
    { 'code': '511000000000', 'title': '内江市' },
    { 'code': '511100000000', 'title': '乐山市' },
    { 'code': '511300000000', 'title': '南充市' },
    { 'code': '511400000000', 'title': '眉山市' },
    { 'code': '511500000000', 'title': '宜宾市' },
    { 'code': '511600000000', 'title': '广安市' },
    { 'code': '511700000000', 'title': '达州市' },
    { 'code': '511800000000', 'title': '雅安市' },
    { 'code': '511900000000', 'title': '巴中市' },
    { 'code': '512000000000', 'title': '资阳市' },
    { 'code': '513200000000', 'title': '阿坝藏族羌族自治州' },
    { 'code': '513300000000', 'title': '甘孜藏族自治州' },
    { 'code': '513400000000', 'title': '凉山彝族自治州' },
    { 'code': '520000000000', 'title': '贵州省' },
    { 'code': '520100000000', 'title': '贵阳市' },
    { 'code': '520200000000', 'title': '六盘水市' },
    { 'code': '520300000000', 'title': '遵义市' },
    { 'code': '520400000000', 'title': '安顺市' },
    { 'code': '520500000000', 'title': '毕节市' },
    { 'code': '520600000000', 'title': '铜仁市' },
    { 'code': '522300000000', 'title': '黔西南布依族苗族自治州' },
    { 'code': '522600000000', 'title': '黔东南苗族侗族自治州' },
    { 'code': '522700000000', 'title': '黔南布依族苗族自治州' },
    { 'code': '530000000000', 'title': '云南省' },
    { 'code': '530100000000', 'title': '昆明市' },
    { 'code': '530300000000', 'title': '曲靖市' },
    { 'code': '530400000000', 'title': '玉溪市' },
    { 'code': '530500000000', 'title': '保山市' },
    { 'code': '530600000000', 'title': '昭通市' },
    { 'code': '530700000000', 'title': '丽江市' },
    { 'code': '530800000000', 'title': '普洱市' },
    { 'code': '530900000000', 'title': '临沧市' },
    { 'code': '532300000000', 'title': '楚雄彝族自治州' },
    { 'code': '532500000000', 'title': '红河哈尼族彝族自治州' },
    { 'code': '532600000000', 'title': '文山壮族苗族自治州' },
    { 'code': '532800000000', 'title': '西双版纳傣族自治州' },
    { 'code': '532900000000', 'title': '大理白族自治州' },
    { 'code': '533100000000', 'title': '德宏傣族景颇族自治州' },
    { 'code': '533300000000', 'title': '怒江傈僳族自治州' },
    { 'code': '533400000000', 'title': '迪庆藏族自治州' },
    { 'code': '540000000000', 'title': '西藏自治区' },
    { 'code': '540100000000', 'title': '拉萨市' },
    { 'code': '540200000000', 'title': '日喀则市' },
    { 'code': '540300000000', 'title': '昌都市' },
    { 'code': '540400000000', 'title': '林芝市' },
    { 'code': '540500000000', 'title': '山南市' },
    { 'code': '540600000000', 'title': '那曲市' },
    { 'code': '542500000000', 'title': '阿里地区' },
    { 'code': '610000000000', 'title': '陕西省' },
    { 'code': '610100000000', 'title': '西安市' },
    { 'code': '610200000000', 'title': '铜川市' },
    { 'code': '610300000000', 'title': '宝鸡市' },
    { 'code': '610400000000', 'title': '咸阳市' },
    { 'code': '610500000000', 'title': '渭南市' },
    { 'code': '610600000000', 'title': '延安市' },
    { 'code': '610700000000', 'title': '汉中市' },
    { 'code': '610800000000', 'title': '榆林市' },
    { 'code': '610900000000', 'title': '安康市' },
    { 'code': '611000000000', 'title': '商洛市' },
    { 'code': '620000000000', 'title': '甘肃省' },
    { 'code': '620100000000', 'title': '兰州市' },
    { 'code': '620200000000', 'title': '嘉峪关市' },
    { 'code': '620300000000', 'title': '金昌市' },
    { 'code': '620400000000', 'title': '白银市' },
    { 'code': '620500000000', 'title': '天水市' },
    { 'code': '620600000000', 'title': '武威市' },
    { 'code': '620700000000', 'title': '张掖市' },
    { 'code': '620800000000', 'title': '平凉市' },
    { 'code': '620900000000', 'title': '酒泉市' },
    { 'code': '621000000000', 'title': '庆阳市' },
    { 'code': '621100000000', 'title': '定西市' },
    { 'code': '621200000000', 'title': '陇南市' },
    { 'code': '622900000000', 'title': '临夏回族自治州' },
    { 'code': '623000000000', 'title': '甘南藏族自治州' },
    { 'code': '630000000000', 'title': '青海省' },
    { 'code': '630100000000', 'title': '西宁市' },
    { 'code': '630200000000', 'title': '海东市' },
    { 'code': '632200000000', 'title': '海北藏族自治州' },
    { 'code': '632300000000', 'title': '黄南藏族自治州' },
    { 'code': '632500000000', 'title': '海南藏族自治州' },
    { 'code': '632600000000', 'title': '果洛藏族自治州' },
    { 'code': '632700000000', 'title': '玉树藏族自治州' },
    { 'code': '632800000000', 'title': '海西蒙古族藏族自治州' },
    { 'code': '640000000000', 'title': '宁夏回族自治区' },
    { 'code': '640100000000', 'title': '银川市' },
    { 'code': '640200000000', 'title': '石嘴山市' },
    { 'code': '640300000000', 'title': '吴忠市' },
    { 'code': '640400000000', 'title': '固原市' },
    { 'code': '640500000000', 'title': '中卫市' },
    { 'code': '650000000000', 'title': '新疆维吾尔自治区' },
    { 'code': '650100000000', 'title': '乌鲁木齐市' },
    { 'code': '650200000000', 'title': '克拉玛依市' },
    { 'code': '650400000000', 'title': '吐鲁番市' },
    { 'code': '650500000000', 'title': '哈密市' },
    { 'code': '652300000000', 'title': '昌吉回族自治州' },
    { 'code': '652700000000', 'title': '博尔塔拉蒙古自治州' },
    { 'code': '652800000000', 'title': '巴音郭楞蒙古自治州' },
    { 'code': '652900000000', 'title': '阿克苏地区' },
    { 'code': '653000000000', 'title': '克孜勒苏柯尔克孜自治州' },
    { 'code': '653100000000', 'title': '喀什地区' },
    { 'code': '653200000000', 'title': '和田地区' },
    { 'code': '654000000000', 'title': '伊犁哈萨克自治州' },
    { 'code': '654200000000', 'title': '塔城地区' },
    { 'code': '654300000000', 'title': '阿勒泰地区' },
    { 'code': '659000000000', 'title': '自治区直辖县级行政区划' },
    { 'code': '710000000000', 'title': '台湾省' },
    { 'code': '810000000000', 'title': '香港特别行政区' },
    { 'code': '820000000000', 'title': '澳门特别行政区' },
]