/*
 * 认证状态 - 修改提交工作(可async)
 */
import md5 from 'js-md5'
import router from '@/router'
// import apiBase from "@/apis/apiBase";
import apis, { isOk, getResMessage } from '@/apis/index'
import cFun from '@/assets/utils/commonFun'

export default {
  // 登陆
  login({ commit, state }, payload) {
    // 开启加载动画 / 禁用按钮
    if (!payload.isAuthLoading) {
      cFun.openDomLoad(payload.loading, payload.loadingDom)
    }
    // 如果用户已经登陆就提示下
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: '请勿重复登陆',
        text: '您以及属于登陆状态!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })
      return false
    }
    // 尝试登陆
    apis
      .ODC('登陆', {
        account: payload.userDetails.account,
        password: payload.isAuthLoading ? payload.userDetails.password : md5(payload.userDetails.password)
      })
      .then(
        result => {
          //关闭加载动画 / 恢复按钮操作
          if (!payload.isAuthLoading) {
            cFun.closeDomLoad(payload.loading, payload.loadingDom)
          }
          // 操作结果
          if (isOk(result)) {
            // 存储信息
            let resData = result.data
            commit('UPDATE_AUTH_USER', resData.userInfo)
            commit('UPDATE_AUTH_MEUN', resData.menu)
            commit('UPDATE_AUTH_SPACES', resData.spaces)
            commit('UPDATE_AUTH_TOKEN', {
              authToken: resData.token,
              authExpire: resData.expireAt
            })
            // 跳转
            router.push(router.currentRoute.query.to || '/')
          } else {
            payload.notify({
              time: 2500,
              title: 'Error',
              text: getResMessage(result),
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        },
        err => {
          //关闭加载动画
          if (!payload.isAuthLoading) {
            cFun.closeDomLoad(payload.loading, payload.loadingDom)
          }
          // 提示用户
          payload.notify({
            time: 2500,
            title: 'Error',
            text: err.message ? err.message : err,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      )
  },
  // 退出登陆
  loginOut({ commit, state }, payload) {
    commit('DELECT_AUTH_USER')
    commit('DELECT_AUTH_TOKEN')

    commit('DELECT_AUTH_MEUN')

    commit('DELECT_AUTH_SPACES')
    commit('DELECT_CURRENT_SPACE')

    // 跳转
    payload.router.push('/pages/login')
  },
  // 自动登陆
  autoLogin({ commit, state }, payload) {
    // 替换用户TOKEN
    commit('UPDATE_AUTH_TOKEN', {
      authToken: payload.token,
      authExpire: payload.expireAt || ''
    })
    // 尝试登陆
    apis.ODC('获取用户权限信息').then(
      result => {
        // 操作结果
        if (isOk(result)) {
          // 存储信息
          let resData = result.data
          commit('UPDATE_AUTH_USER', resData.userInfo)
          commit('UPDATE_AUTH_MEUN', resData.menu)
          commit('UPDATE_AUTH_SPACES', resData.spaces)
          // 跳转
          router.push(router.currentRoute.query.to || '/')
        } else {
          payload.notify({
            time: 2500,
            title: 'Error',
            text: getResMessage(result),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      },
      err => {
        // 提示用户
        payload.notify({
          time: 2500,
          title: 'Error',
          text: '自动登陆失败',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    )
  }
}
