/* eslint-disable prettier/prettier */
import localApis from './local/index'
import onlineApis from './online/request'
import equipmentApis from './online/request_local'

// 是否使用本地数据(不调用接口)
// const uselocalMockData = false;

// 接口暂时
const apiMaps = {
  // 登陆
  登陆: { online: '/login', action: 'POST', local: 'login' },
  获取用户权限信息: { online: '/userAuthorList', action: 'GET', local: 'userAuthorList' },
  获取当前用户菜单: { online: '/getMenusByUserID', action: 'GET', local: 'getMenusByUserID' },
  重置当前用户密码: { online: '/resetPwd', action: 'POST', local: 'resetPwd' },
  获取用户区域权限: { online: '/getSpaceByUserID', action: 'GET', local: 'getSpaceByUserID' },
  // 角色
  获取角色: { online: '/getRole', action: 'GET', local: 'getRole' },
  获取用户菜单: { online: '/getMenus', action: 'GET', local: 'getMenus' },
  添加角色: { online: '/addRole', action: 'POST', local: 'addRole' },
  更新角色: { online: '/updateRole', action: 'POST', local: 'updateRole' },
  删除角色: { online: '/deleteRole', action: 'DELETE', local: 'deleteRole' },
  // 用户
  获取用户列表: { online: '/getUser', action: 'GET', local: 'getUser' },
  添加用户: { online: '/addUser', action: 'POST', local: 'addUser' },
  修改用户密码: { online: '/modifyUserPwd', action: 'POST', local: 'modifyUserPwd' },
  修改用户角色: { online: '/modifyUserRole', action: 'POST', local: 'modifyUserRole' },
  修改用户禁用和SPACE: { online: '/modifyUserSpace', action: 'POST', local: 'modifyUserSpace' },
  删除用户: { online: '/deleteUser', action: 'DELETE', local: 'deleteUser' },
  修改用户信息: { online: '/modifyUser', action: 'POST', local: 'modifyUser' },
  // 空间
  获取小区模版数据: { online: '/getSpaceCode', action: 'POST', local: 'getSpaceCode' },
  //（原接口addSpace保存）
  新建小区接口: { online: '/AddVillageSpace', action: 'POST', local: 'AddVillageSpace' },
  获取空间列表: { online: '/space', action: 'GET', local: 'space' },
  添加空间: { online: '/addSpace', action: 'POST', local: 'addSpace' },
  修改空间: { online: '/modifySpace', action: 'PUT', local: 'modifySpace' },
  删除空间: { online: '/delSpace', action: 'DELETE', local: 'delSpace' },
  获取楼栋列表: { online: '/buildingList', action: 'GET', local: 'buildingList' },
  添加楼栋: { online: '/addBuilding', action: 'POST', local: 'addBuilding' },
  修改楼栋: { online: '/modifyBuilding', action: 'PUT', local: 'modifyBuilding' },
  删除楼栋: { online: '/delBuilding', action: 'DELETE', local: 'delBuilding' },
  获取单元列表: { online: '/unitList', action: 'GET', local: 'unitList' },
  添加单元: { online: '/addUnit', action: 'POST', local: 'addUnit' },
  修改单元: { online: '/modifyUnit', action: 'PUT', local: 'modifyUnit' },
  删除单元: { online: '/delUnit', action: 'DELETE', local: 'delUnit' },
  获取房屋列表: { online: '/houseList', action: 'GET', local: 'houseList' },
  添加房屋: { online: '/addHouse', action: 'POST', local: 'addHouse' },
  修改房屋: { online: '/modifyHouse', action: 'PUT', local: 'modifyHouse' },
  删除房屋: { online: '/delHouse', action: 'DELETE', local: 'delHouse' },
  获取空间树形结构: { online: '/spaceTree', action: 'POST', local: 'spaceTree' },
  // 设备
  获取设备列表: { online: '/deviceList', action: 'GET', local: 'deviceList' },
  添加设备: { online: '/addDevice', action: 'POST', local: 'addDevice' },
  更新设备: { online: '/updateDevice', action: 'PUT', local: 'updateDevice' },
  删除设备: { online: '/delDevice', action: 'DELETE', local: 'delDevice' },
  获取设备管理数据: { online: '/getDeviceCount', action: 'POST', local: 'getDeviceCount' },
  // 关系
  获取关系内容: { online: '/relation', action: 'POST', local: 'relation' },
  // 点位
  获取出入口列表: { online: '/inoutList', action: 'GET', local: 'inoutList' },
  获取出入口详情: { online: '/inoutDetail', action: 'GET', local: 'inoutDetail' },
  获取设备列表根据出入口类型: { online: '/deviceListByInoutType', action: 'POST', local: 'deviceListByInoutType' },
  添加出入口: { online: '/addInout', action: 'POST', local: 'addInout' },
  更新出入口: { online: '/updateInout', action: 'PUT', local: 'updateInout' },
  删除出入口: { online: '/delInout', action: 'DELETE', local: 'delInout' },
  // 通行组
  获取通行组列表: { online: '/groupList', action: 'GET', local: 'groupList' },
  获取通行组详情: { online: '/groupDetail', action: 'GET', local: 'groupDetail' },
  获取通行组可选的出入口: { online: '/groupChooseInoutList', action: 'GET', local: 'groupChooseInoutList' },
  添加通行组: { online: '/addGroup', action: 'POST', local: 'addGroup' },
  更新通行组: { online: '/updateGroup', action: 'PUT', local: 'updateGroup' },
  删除通行组: { online: '/delGroup', action: 'DELETE', local: 'delGroup' },
  获取用户通行组列表: { online: '/collection/peopleGroupList', action: 'GET', local: 'peopleGroupList' },
  获取管理通行组列表: { online: '/collection/getManagePG', action: 'POST', local: 'getManagePG' },
  // 图片视频服务器
  单图片上传: { online: '/file_service/picture/upload', action: 'POST', local: 'pictureUpload' },
  特征值上传: { online: '/file_service/fea/upload', action: 'POST', local: 'feaUpload' },
  // 人员
  新增人员: { online: '/collection/people/addPeople', action: 'POST', local: 'addPeople' },
  更新人员: { online: '/collection/people/updatePeople', action: 'POST', local: 'updatePeople' },
  保存人房关系: { online: '/collection/people/addPeopleHouse', action: 'POST', local: 'addPeopleHouse' },
  编辑人房关系: { online: '/collection/people/updatePeopleHouse', action: 'PUT', local: 'updatePeopleHouse' },
  获取人员信息: { online: '/collection/people/getPeopleInfo', action: 'POST', local: 'getPeopleInfo' },
  删除人房关系: { online: '/collection/people/delPeopleHouse', action: 'DELETE', local: 'delPeopleHouse' },
  注销住户: { online: '/collection/people/delPeople', action: 'POST', local: 'delPeople' },
  获取人房关系: { online: '/collection/people/getPeopleHouseInfo', action: 'POST', local: 'getPeopleHouseInfo' },
  获取住户列表: { online: '/collection/people/getPeopleList', action: 'POST', local: 'getPeopleList' },
  获取住户列表个数: { online: '/collection/people/getPeopleCount', action: 'POST', local: 'getPeopleCount' },
  获取住户人房关系: { online: '/collection/people/getPeopleHouseList', action: 'POST', local: 'getPeopleHouseList' },
  获取卡列表: { online: '/collection/people/getCardList', action: 'POST', local: 'getCardList' },
  挂失: { online: '/collection/people/lostCard', action: 'POST', local: 'lostCard' },
  取消挂失: { online: '/collection/people/removeLostCard', action: 'POST', local: 'removeLostCard' },
  删除卡和卡授权: { online: '/collection/people/delCard', action: 'POST', local: 'delCard' },
  插入或者修改卡和卡授权: { online: '/collection/people/insertOrModifyCardAuth', action: 'POST', local: 'insertOrModifyCardAuth' },
  获取是否勾选主呼叫人: { online: '/collection/people/getMainCall', action: 'POST', local: 'getMainCall' },
  插入人车关系: { online: '/collection/people/insertVehicles', action: 'POST', local: 'insertVehicles' },
  获得人车关系: { online: '/collection/people/getVehiclesByPeopleID', action: 'POST', local: 'getVehiclesByPeopleID' },
  删除人车关系: { online: '/collection/people/delVehicles', action: 'POST', local: 'delVehicles' },
  获取房屋是否有户主: { online: '/collection/house/ownerExist', action: 'GET', local: 'getHouseOwnerExist' },
  根据身份证获取人员信息: { online: '/collection/people/getInfoByCertificateNo', action: 'GET', local: 'getInfoByCertificateNo' },
  通过卡号获取人员信息: { online: '/collection/people/getPeopleIDByCardNo', action: 'POST', local: 'getPeopleIDByCardNo' },
  // 统计数据
  人口统计数据: { online: '/statistical/population', action: 'GET', local: 'statisticalPopulation' },
  房屋统计数据: { online: '/statistical/house', action: 'GET', local: 'statisticalHouse' },
  设备统计数据: { online: '/statistical/device', action: 'GET', local: 'statisticalDevice' },
  采集统计数据: { online: '/statistical/collection', action: 'GET', local: 'statisticalCollection' },
  // 黑白名单
  黑白名单设备列表: { online: '/getBlackWhiteDeviceListdetail', action: 'POST', local: 'getBlackWhiteDeviceListdetail' },
  黑白名单设备数量: { online: '/getBlackWhiteDeviceListCount', action: 'POST', local: 'getBlackWhiteDeviceListCount' },
  获取单个设备黑白名单列表: { online: '/getBlackWhiteList', action: 'POST', local: 'getBlackWhiteList' },
  获取单个设备黑白名单数量: { online: '/getBlackWhiteCount', action: 'POST', local: 'getBlackWhiteCount' },
  获取单个设备人脸数量: { online: '/getFaceCount', action: 'POST', local: 'getFaceCount' },
  获取单个设备人脸列表: { online: '/getFacelist', action: 'POST', local: 'getFacelist' },
  // 人脸设备
  人脸设备列表: { online: '/getFaceDeviceList', action: 'POST', local: 'getFaceDeviceList' },
  人脸设备数量: { online: '/getFaceDeviceCount', action: 'POST', local: 'getFaceDeviceCount' },
  // 身份证-读卡一体机
  读身份证: { online: '/ReaderIDCard', action: 'POST', local: 'ReaderIDCard', isEquipment: true },
  读卡: { online: '/ReadCard', action: 'POST', local: 'ReadCard', isEquipment: true },
  写卡: { online: '/WriteCard', action: 'POST', local: 'WriteCard', isEquipment: true },
  清卡: { online: '/EraseCard', action: 'POST', local: 'EraseCard', isEquipment: true },
  读卡号: { online: '/ReadACCardID', action: 'POST', local: 'ReadACCardID', isEquipment: true },
  人脸比对: { online: '/CompareFace/compare', action: 'POST', local: 'CompareFace', isEquipment: true },
  读取设备状态: { online: '/IsReaderOnline', action: 'POST', local: 'IsReaderOnline', isEquipment: true },
  // 日志
  获取通行记录: { online: '/getAccessLog', action: 'POST', local: 'getAccessLog' },
  // sip
  获取房屋内的sip呼叫信息: { online: '/collection/sip/houseSipInfo', action: 'GET', local: 'getHouseSipInfo' },
  更新房屋呼叫顺序排序: { online: '/collection/sip/sortHouseSip', action: 'PUT', local: 'pushSortHouseSip' },
  // 在线审核（移动端人房）
  获取审核列表: { online: '/collection/people/peopleHouseSelfReporting', action: 'POST', local: 'getHouseSipInfo' },
  更新审核状态: { online: '/collection/people/updatePeopleHouseSelfReportingState', action: 'POST', local: 'getHouseSipInfo' }
}

// 数据统一出入口 （Operate Data Changes)
async function ODC(key, params = {}) {
  if (currentUselocalMockData()) {
    // console.group("%c 正在使用本地数据", 'font-size:18px;color:red;');
    return eval(localApis[apiMaps[key].local](params))
  } else {
    // console.group("%c 正在使用线上数据",'font-size:18px;color:red;');
    if (apiMaps[key].isEquipment === true) {
      return eval(equipmentApis[apiMaps[key].action.toLocaleUpperCase()](apiMaps[key].online, params))
    } else {
      return eval(onlineApis[apiMaps[key].action.toLocaleUpperCase()](apiMaps[key].online, params))
    }
  }
}

// 是否使用本地数据(不调用接口) (false 线上(default) true 本地)
function currentUselocalMockData() {
  let bol = false
  if (typeof window.uselocalMockData === 'boolean') {
    bol = window.uselocalMockData
  }
  return bol
}

//  根据情况决定 返回TRUE/FLASE
export function isOk(res) {
  if (res.responseStatus) {
    if (res.responseStatus.resultCode === '0') {
      return true
    }
  }
  return false
}

// 获取服务器返回message
export function getResMessage(res) {
  if (res.responseStatus) {
    if (res.responseStatus.resultMessage) {
      return res.responseStatus.resultMessage
    }
  }
  return ' '
}

export default {
  ODC,
  getResMessage,
  isOk
}
