// 所有api调用
import loginResult from "../mockData/登陆返回数据.json";

export default {
  // 登陆
  login(params) {
    return new Promise((resolve, reject) => {
      if (typeof params.account != "string")
        return reject(new Error("account must be string"));
      else if (typeof params.password != "string")
        return reject(new Error("password must be string"));

      setTimeout(() => {
        resolve(loginResult);
      }, 1000);
    });
  }
};
