import Vue from "vue";
import Vuex from "vuex";

// 通用的
import state from "./common/wholeState";
import getters from "./common/wholeGetters";
import mutations from "./common/wholeMutations";
import actions from "./common/wholeActions";
// 指定模块的
import moduleAuth from "./auth/moduleAuth.js";

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth
  },
  // 严格模式(严格模式会深度监测状态树来检测不合规的状态变更——请确保在发布环境下关闭严格模式，以避免性能损失)
  strict: process.env.NODE_ENV !== "production"
});
