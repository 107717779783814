/*
 * 通用状态-数据的集合(必须sync)
 */
const mutations = {
  // UI 和 侧边栏
  UPDATE_SIDEBAR_WIDTH(state, width) {
    state.sidebarWidth = width;
  },
  UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
    state.sidebarItemsMin = val;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
  },
  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val;
  },
  TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
    state.isSidebarActive = value;
  },
  UPDATE_THEME(state, val) {
    state.theme = val;
    localStorage.setItem("theme", val);
  },
  UPDATE_ROUTER_TRANSITION(state, val) {
    state.routerTransition = val;
    localStorage.setItem("routerTransition", val);
  },
  UPDATE_NAVBAR_COLOR(state, val) {
    state.navbarColor = val;
    localStorage.setItem("navbarColor", val);
  },
  UPDATE_WINDOW_BREAKPOINT(state, val) {
    state.breakpoint = val;
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val;
    localStorage.setItem("themePrimaryColor", val);
  },
  UPDATE_USER_ROLE(state, val) {
    state.userRole = val;
    localStorage.setItem("userRole", val);
  },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },
  UPDATE_WINDOW_HEIGHT(state, height) {
    state.windowHeight = height;
  },
  // 搜索框
  UPDATE_STARRED_PAGE(state, payload) {
    const index = state.navbarSearchAndPinList.data.findIndex(
      item => item.index == payload.index
    );
    state.navbarSearchAndPinList.data[index].highlightAction = payload.val;
    if (payload.val) {
      state.starredPages.push(state.navbarSearchAndPinList.data[index]);
    } else {
      const index = state.starredPages.findIndex(
        item => item.index == payload.index
      );
      state.starredPages.splice(index, 1);
    }
  },
  //  导航栏
  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10);
    state.starredPages = list.concat(starredPagesMore);
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false;
    let lastItemInStarredLimited = state.starredPages[10];
    const starredPagesLimited = state.starredPages.slice(0, 10);
    state.starredPages = starredPagesLimited.concat(list);

    state.starredPages.slice(0, 10).map(i => {
      if (list.indexOf(i) > -1) downToUp = true;
    });
    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited);
    }
  }
};

export default mutations;
