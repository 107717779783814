// 采用axios get/post/update/delete
import axios from "axios";
import Qs from "qs";
import router from "@/router";
import store from "../../store/store";
import utils from "../../assets/utils/commonFun"

console.log(utils.getUrlPrefix())
// let host = utils.getUrlPrefix()
let host = "http://127.0.0.1"

// 路由头
const instance = axios.create({
  baseURL: host + "/api/card/",
  timeout: 40000,
  headers: {
    'Content-Type': 'application/json'
  },
})

// axios.defaults.headers.post["Content-Type"] = "application/json";

// axios.defaults.baseURL = "http://127.0.0.1:7788";
// 设置请求超时时间
// axios.defaults.timeout = 8000;
// 设置post请求头

// 请求拦截
// instance.interceptors.request.use(
//   config => {
//     console.log("请求接口参数 :", config);
//     const NoAuthTokenURL = ["/login"];
//     let token = localStorage.getItem("authTokenInfo")
//       ? JSON.parse(localStorage.getItem("authTokenInfo")).authToken
//       : undefined;
//     if (token && !NoAuthTokenURL.includes(config.url)) {
//       config.headers = {
//         Authorization: `Bearer ${token}`
//       };
//     }
//     return config;
//   },
//   error => {
//     return Promise.error(error);
//   }
// );

// 响应拦截
instance.interceptors.response.use(
  response => {
    console.log("请求接口返回:", response);
    console.groupEnd();
    if (response.status === 200) {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 400:
          error = "参数异常";
          break;
        case 401:
          error = "访问系统未经授权";
          // 退出
          store.dispatch("auth/loginOut",{ router: router });
          break;
        case 403:
          error = "访问系统无权限";
          break;
        case 404:
          error = "您访问的网页不存在。";
          break;
        default:
          error = "访问系统失败";
      }
    }
    return Promise.reject(error);
  }
);

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function GET(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, { params: params })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function POST(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * QSpost方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function QSPOST(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, Qs.stringify(params))
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function PUT(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .put(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

/**
 * delete
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function DELETE(url, params) {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, { data: params })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export default {
  GET,
  POST,
  QSPOST,
  PUT,
  DELETE
};
