/*=========================================================================================
  路由参数备注：
    path => 路由地址
    name => 路由名次
    component(lazy loading) => 组件加载
    meta : {
      rule => 启动ACL 才能使用（暂时遗弃）
      breadcrumb => 面包屑
      pageTitle => 当前页名称 (展示在面包屑)
    }

==========================================================================================*/

import Vue from 'vue'
import Router from 'vue-router'

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      /* =============================================================================
       * 通用页面 路由
      ============================================================================= */
      path: '',
      component: () => import('@/layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          redirect: '/home'
        },
        {
          path: '/home',
          name: 'home',
          component: () => import('./views/Home.vue')
        },
        {
          path: '/profile/userInfo',
          name: 'userInfo',
          component: () => import('./views/pages_business/profile/UserInfo.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '系统设置'
              },
              {
                title: '用户信息',
                active: true
              }
            ],
            pageTitle: '用户信息'
          }
        },
        {
          path: '/profile/resetPassword',
          name: 'resetPassword',
          component: () => import('./views/pages_business/profile/ResetPassword.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '系统设置'
              },
              {
                title: '重置密码',
                active: true
              }
            ],
            pageTitle: '重置密码'
          }
        },
        {
          path: '/profile/role',
          name: 'role',
          component: () => import('./views/pages_business/profile/Role.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '系统设置'
              },
              {
                title: '角色管理',
                active: true
              }
            ],
            pageTitle: '角色管理'
          }
        },
        {
          path: '/profile/account',
          name: 'account',
          component: () => import('./views/pages_business/profile/Account.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '系统设置'
              },
              {
                title: '账户管理',
                active: true
              }
            ],
            pageTitle: '账户管理'
          }
        },
        {
          path: '/profile/customize',
          name: 'customize',
          component: () => import('./views/pages_business/profile/Customize.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '系统设置'
              },
              {
                title: '系统配置',
                active: true
              }
            ],
            pageTitle: '系统配置'
          }
        },
        {
          path: '/community/flowMain',
          name: 'communityFlowMain',
          component: () => import('./views/pages_business/manage_backUp/community/Village.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '管理平台'
              },
              {
                title: '区域管理',
                active: true
              }
            ],
            pageTitle: '区域管理'
          }
        },
        {
          path: '/community/buildingList/:id',
          name: 'communityBuildingList',
          component: () => import('./views/pages_business/manage_backUp/community/Building'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '管理平台'
              },
              {
                title: '区域管理'
              },
              {
                title: '楼栋管理',
                active: true
              }
            ],
            pageTitle: '楼栋管理'
          }
        },
        {
          path: '/equipment/flowMain',
          name: 'equipmentFlowMain',
          component: () => import('./views/pages_business/manage_backUp/equipment/Equipment.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '管理平台'
              },
              {
                title: '设备管理',
                active: true
              }
            ],
            pageTitle: '设备管理'
          }
        },
        {
          path: '/equipment/EquipmentList/:type',
          name: 'equipmentList',
          component: () => import('./views/pages_business/manage_backUp/equipment/EquipmentList.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '管理平台'
              },
              {
                title: '区域管理'
              },
              {
                title: '设备管理',
                active: true
              }
            ],
            pageTitle: '设备管理'
          }
        },
        {
          path: '/equipment/InoutList/:name/:type',
          name: 'inoutList',
          component: () => import('./views/pages_business/manage_backUp/equipment/InoutList.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '管理平台'
              },
              {
                title: '区域管理'
              },
              {
                title: '点位管理',
                active: true
              }
            ],
            pageTitle: '点位管理'
          }
        },
        {
          path: '/equipment/groupList/:name/:type',
          name: 'groupList',
          component: () => import('./views/pages_business/manage_backUp/equipment/GroupList.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '管理平台'
              },
              {
                title: '区域管理'
              },
              {
                title: '通行组管理',
                active: true
              }
            ],
            pageTitle: '通行组管理'
          }
        },
        {
          path: '/house/flowMain',
          name: 'houseFlowMain',
          component: () => import('./views/pages_business/manage_peopleInfo/house/flow_main.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '管理平台'
              },
              {
                title: '房屋管理',
                active: true
              }
            ],
            pageTitle: '房屋管理'
          }
        },
        {
          path: '/people/flowMain/:peopleID?',
          name: 'peopleFlowMain',
          component: () => import('./views/pages_business/manage_peopleInfo/people/flow_main.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '采集平台'
              },
              {
                title: '人员管理',
                active: true
              }
            ],
            pageTitle: '人员管理'
          }
        },
        {
          path: '/people/peopleList',
          name: 'peopleList',
          component: () => import('./views/pages_business/manage_peopleInfo/people/people_list.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '采集平台'
              },
              {
                title: '人员列表',
                active: true
              }
            ],
            pageTitle: '人员列表'
          }
        },
        {
          path: '/people/peopleImport',
          name: 'peopleImport',
          component: () => import('./views/pages_business/manage_peopleInfo/people/people_import.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '采集平台'
              },
              {
                title: '人员导入',
                active: true
              }
            ],
            pageTitle: '人员导入'
          }
        },
        {
          path: '/send/blackAndwhiteList',
          name: 'blackAndwhiteList',
          component: () => import('./views/pages_business/manage_sendList/blackAndWhite/list.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '采集平台'
              },
              {
                title: '黑白名单列表',
                active: true
              }
            ],
            pageTitle: '黑白名单列表'
          }
        },
        {
          path: '/send/sipList',
          name: 'sipList',
          component: () => import('./views/pages_business/manage_sendList/sipList/list.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '采集平台'
              },
              {
                title: '呼叫管理',
                active: true
              }
            ],
            pageTitle: '呼叫管理'
          }
        },
        {
          path: '/send/sipList',
          name: 'sipList',
          component: () => import('./views/pages_business/manage_sendList/sipList/list.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '采集平台'
              },
              {
                title: '呼叫管理',
                active: true
              }
            ],
            pageTitle: '呼叫管理'
          }
        },
        {
          path: '/send/blackAndwhiteList/:deviceID',
          name: 'blackAndwhiteDetail',
          component: () => import('./views/pages_business/manage_sendList/blackAndWhite/single_device.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '采集平台'
              },
              {
                title: '黑白名单列表'
              },
              {
                title: '设备-黑白名单',
                active: true
              }
            ],
            pageTitle: '设备-黑白名单'
          }
        },
        {
          path: '/send/peopleHouseManagement',
          name: 'peopleHouseManagement',
          component: () => import('./views/pages_business/manage_sendList/peopleHouse/list.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '采集平台'
              },
              {
                title: '人房审核',
                active: true
              }
            ],
            pageTitle: '人房审核'
          }
        },
        {
          path: '/send/faceList',
          name: 'faceList',
          component: () => import('./views/pages_business/manage_sendList/faceList/list.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '采集平台'
              },
              {
                title: '人脸下发列表',
                active: true
              }
            ],
            pageTitle: '人脸下发列表'
          }
        },
        {
          path: '/send/faceList/:deviceID',
          name: 'faceDownDetail',
          component: () => import('./views/pages_business/manage_sendList/faceList/single_device.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '采集平台'
              },
              {
                title: '人脸下发列表'
              },
              {
                title: '设备-人脸下发',
                active: true
              }
            ],
            pageTitle: '设备-人脸下发'
          }
        },
        {
          path: '/log/passLogList',
          name: 'passLogList',
          component: () => import('./views/pages_business/manage_logs/passLogList/list.vue'),
          meta: {
            breadcrumb: [
              {
                title: '首页'
              },
              {
                title: '采集平台'
              },
              {
                title: '日志管理'
              },
              {
                title: '通行日志',
                active: true
              }
            ],
            pageTitle: '通行日志'
          }
        }
      ]
    },
    /* =============================================================================
      * 全屏页面 路由
    ============================================================================= */
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        {
          path: '/pages/error-404',
          name: 'pageError404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/pages/error-500',
          name: 'pageError500',
          component: () => import('@/views/pages/Error500.vue')
        },
        {
          path: '/pages/notAuthorized',
          name: 'notAuthorized',
          component: () => import('@/views/pages/NotAuthorized.vue')
        },
        {
          path: '/pages/login',
          name: 'pageLogin',
          component: () => import('@/views/pages/Login.vue')
        }
      ]
    },
    {
      path: '*',
      redirect: '/pages/error-404',
      hidden: true
    }
  ]
})

/**
 * @description: 路由结束后触发;
 */
router.afterEach(() => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

/**
 * @description: 路由进入后触发;
 */
router.beforeEach((to, from, next) => {
  // 逃避认证(直接进入)
  let authPathArr = ['/', '/pages/login', '/pages/error-404', '/pages/error-500', '/pages/notAuthorized', '/profile/customize', '/profile/userInfo', '/profile/resetPassword']

  // console.log(to.path)

  if (!authPathArr.includes(to.path) && !localStorage.getItem('userInfo')) {
    router.push({ path: '/pages/login', query: { to: to.path } })
  } else if (!authPathArr.includes(to.path) && !checkRouterIsAuth(to.path)) {
    router.push({ path: '/pages/notAuthorized', query: { to: to.path } })
  } else {
    return next()
  }
})

export default router

// 判断当前路由是否可以通过认证 （可以通过 return true)
function checkRouterIsAuth(toPath) {
  // 菜单认证(否 to NotAuthorized)
  let authPath = JSON.parse(localStorage.getItem('authPath')) || []

  authPath = authPath.map(item => {
    if (!item) return false
    let arr = item.split('/')
    arr.splice(
      arr.findIndex(item => item == ''),
      1
    )
    return arr[0]
  })

  // 当前路由 只要包含 指定一级 就算通过
  let isAuthArr = authPath.map(item => toPath.includes(item))

  return isAuthArr.includes(true) ? true : false
}
