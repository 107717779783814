// 控制弹框
const OPTRATE = {
  C: "CREATE",
  R: "READ",
  U: "UPDATE",
  D: "DELETE",
  A: "AUTH",
  RP: "RESTPWD"
};

const CONSTANT = {
  OPTRATE: OPTRATE
};

export default CONSTANT;
