<template>
  <div id="app">
    <!-- 路由 -->
    <router-view></router-view>
  </div>
</template>

<script>
import themeConfig from "@/../themeConfig.js";

export default {
  created() {
    this.handleWindowResize({
      currentTarget: {
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth
      }
    });
  },
  watch: {
    "$store.state.theme"(val) {
      this.toggleClassInBody(val);
    }
  },
  methods: {
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
        document.body.classList.add("theme-dark");
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        document.body.classList.add("theme-semi-dark");
      } else {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
      }
    },
    handleWindowResize(event) {
      console.log(
        "当前内宽: %d, 当前内高: %d",
        event.currentTarget.innerWidth,
        event.currentTarget.innerHeight
      );
      this.$store.dispatch("updateWindowSize", [
        event.currentTarget.innerWidth,
        event.currentTarget.innerHeight
      ]);
    }
  },
  mounted() {
    this.$vs.theme({ primary: this.$store.state.themePrimaryColor });
    this.toggleClassInBody(localStorage.getItem("theme") || themeConfig.theme);
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
};
</script>
