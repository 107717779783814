/*
 * 通用状态-修改提交工作(可async)
 */
const actions = {
  // UI 和 侧边栏
  updateSidebarWidth({ commit }, width) {
    commit("UPDATE_SIDEBAR_WIDTH", width);
  },
  updateI18nLocale({ commit }, locale) {
    commit("UPDATE_I18N_LOCALE", locale);
  },
  toggleContentOverlay({ commit }) {
    commit("TOGGLE_CONTENT_OVERLAY");
  },
  updateTheme({ commit }, val) {
    commit("UPDATE_THEME", val);
  },
  updateRouterTransition({ commit }, val) {
    commit("UPDATE_ROUTER_TRANSITION", val);
  },
  updateNavbarColor({ commit }, val) {
    commit("UPDATE_NAVBAR_COLOR", val);
  },
  updateUserRole({ commit }, val) {
    commit("UPDATE_USER_ROLE", val);
  },
  updateWindowSize({ commit }, size) {
    commit("UPDATE_WINDOW_WIDTH", size[0]);
    commit("UPDATE_WINDOW_HEIGHT", size[1]);
  },
  // 搜索框
  updateStarredPage({ commit }, payload) {
    commit("UPDATE_STARRED_PAGE", payload);
  },
  //  导航栏
  arrangeStarredPagesLimited({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_LIMITED", list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_MORE", list);
  }
};

export default actions;
