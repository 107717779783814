/*
 * 整体配置文件
 */

// 主要颜色 || vuesax 各报警的主题色
let colors = {
  primary: "#7367F0",
  success: "#28C76F",
  danger: "#EA5455",
  warning: "#FF9F43",
  dark: "#1E1E1E",
  yellow: "#e5ff0e",
  purple: "#9b24eb",
  orange: "#ff9f43"
};

import Vue from "vue";
import Vuesax from "vuesax";
Vue.use(Vuesax, { theme: { colors } });

// 全局配置状态入口
const themeConfig = {
  // 默认主题 ['light', 'dark', 'semi-dark']
  theme: "light",
  // 默认主题颜色
  themePrimaryColor: colors.primary,
  // 侧边栏开关 [true, false(default)]
  sidebarCollapsed: false,
  // 顶部条颜色 HEX color / rgb / rgba 都可以
  navbarColor: "#fff",
  // 过长动画 [zoom-fade,slide-fade,fade-bottom,fade,zoom-out,none(default)]
  routerTransition: "slide-fade"
};

export default themeConfig;
