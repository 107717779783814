export default {
  // 判断空
  isNullOrEmpty(str) {
    if (str == null || str === '') {
      return true
    }
    if (typeof str === 'string' && str.constructor === String) {
      if (str.replace(/\s+/g, '') === '') {
        return true
      }
    }
    return false
  },
  // 调整时间格式
  formatTime(data, fmt) {
    data = data ? new Date(data) : new Date()
    fmt = fmt ? fmt : 'yyyy-MM-dd hh:mm:ss'
    var o = {
      'M+': data.getMonth() + 1, //月份
      'd+': data.getDate(), //日
      'h+': data.getHours(), //小时
      'm+': data.getMinutes(), //分
      's+': data.getSeconds() //秒
    }
    if (/(y+)/.test(fmt)) {
      //根据y的长度来截取年
      fmt = fmt.replace(RegExp.$1, (data.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
    return fmt
  },
  // 获取当前url的前缀
  getUrlPrefix() {
    const proto = location.protocol
    const host = window.location.host
    const url = `${proto}//${host}`
    if (url.includes('localhost') || url.includes('127.0.0.1')) {
      return 'http://127.0.0.1'
    } else {
      return url
    }
  },
  // 判断手机还是PC
  isPC() {
    var userAgentInfo = navigator.userAgent
    var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
    var flag = true
    for (var v = 0; v < Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v]) > 0) {
        flag = false
        break
      }
    }
    return flag
  },
  // API 筛选 (仅存mockData理想化)
  formatDataForApi(data) {
    let formattedData = data.map(item => {
      const fields = item.fields
      let obj = {}
      for (const key of Object.keys(fields)) {
        obj[key] = Number(fields[key].integerValue) || Number(fields[key].doubleValue) || fields[key].stringValue
      }
      return obj
    })
    return formattedData
  },
  // 验证对象内部是否全空 （遇到[],多层OBJ,Bol 暂不判断 直接返回false)
  isNullOrEmptyForObJ(data) {
    let arr = []
    for (const key of Object.keys(data)) {
      arr.push(this.isNullOrEmpty(data[key]))
    }
    if (arr.includes(true)) {
      return true
    }
    return false
  },
  // 全局按钮loading事件 (开)
  openDomLoad(loading, dom, background, color, type) {
    let el = dom ? dom : event.currentTarget
    let conf = {
      background: background ? background : 'primary',
      color: color ? color : '#fff',
      type: type ? type : 'sound',
      container: el,
      scale: 0.45
    }
    // 禁用按钮
    el.disabled = true
    // 加载触发
    loading(conf)
  },
  // 全局按钮loading事件 (关)
  closeDomLoad(loading, dom) {
    let el = dom ? dom : event.currentTarget
    // 取消触发
    loading.close(el)
    // 禁用按钮
    el.disabled = false
  },
  // 导航栏阴影问题 组件联动 (true, false)
  changeNavBarShadow(val) {
    var dom = document.getElementById('content-area')
    if (dom) {
      if (val) {
        dom.setAttribute(
          'class',
          dom
            .getAttribute('class')
            .concat(' ')
            .concat('dialog-show-overlay')
        )
      } else {
        dom.setAttribute(
          'class',
          dom
            .getAttribute('class')
            .replace(' ', '')
            .replace('dialog-show-overlay', '')
        )
      }
    }
  },
  // 提示框
  showNotify(notify, state, text) {
    let title,
      color = ''
    switch (state) {
      case 'success':
        title = '成功'
        color = 'success'
        break
      case 'danger':
        title = '错误'
        color = 'danger'
        break
      case 'warning':
        title = '警告'
        color = 'warning'
        break
    }
    notify({
      time: 2500,
      title: title,
      text: text,
      iconPack: 'feather',
      icon: 'icon-alert-circle',
      color: color
    })
  },
  /*******
   * @description:
   * @param {string} str 加密的电话号码
   * @return {string}
   */
  encryptPhone(str) {
    if (null != str && str != undefined) {
      var pat = /(\d{3})\d*(\d{4})/
      return str.replace(pat, '$1****$2')
    } else {
      return '-'
    }
  },
  /*******
   * @description:
   * @param {string} str 需要加密的名字
   * @return {string}
   */
  encryptName(str) {
    if (null != str && str != undefined) {
      return '*' + str.substring(1, str.length) //截取name 字符串截取第一个字符，
    } else {
      return '-'
    }
  },
  /*******
   * @description:
   * @param {string} str 需要加密的身份证 普通规则
   * @param {string} type 1:身份证件 2:其他证件
   * @return {string}
   */
  encryptIDNumber(str, type) {
    if (null != str && str != undefined) {
      var pat = /(\w{1})\w*(\w{1})/
      if (type == '1') {
        return str.replace(pat, '$1*****************$2')
      } else {
        return str.replace(pat, '$1****$2')
      }
    } else {
      return '-'
    }
  }
}
