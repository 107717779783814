/*
 * 认证状态
 */
export default {
  isUserLoggedIn: () => {
    let isAuthenticated = true;
    return localStorage.getItem("userInfo") && isAuthenticated;
  },
  rmenu: JSON.parse(localStorage.getItem("rmenu")) || [],
  spaces: JSON.parse(localStorage.getItem("spaces")) || [],
  space: JSON.parse(localStorage.getItem("space")) || {},
  account: JSON.parse(localStorage.getItem("userInfo"))
    ? JSON.parse(localStorage.getItem("userInfo")).account
    : ""
};
